import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 *  分页查询
 * @param params
 */
export function findAirTimeList(params) {
    return request({
      url: envInfo.bgApp.customerWXPath + '/order/queryCustomerApplyList',
      method: 'get',
      params,
    });
  }

  /**
 *  详情
 * @param params
 */
export function queryOrderById(params) {
  return request({
    url: envInfo.bgApp.customerWXPath + '/order/queryOrderById',
    method: 'get',
    params,
  });
}

/**
 * 月卡状态修改
 * @param params
 */
export function editCardStatus(params) {
  return request({
    url: envInfo.bgApp.customerWXPath + '/paidOrder/monthlySettlementFlag',
    method: 'post',
    data: params,
  });
}

/**
 * 开票状态修改
 * @param params
 */
export function editInvoiceStatus(params) {
  return request({
    url: envInfo.bgApp.customerWXPath + '/paidOrder/editInvoiceStatus',
    method: 'post',
    data: params,
  });
}

  /**
 *  导出
 * @param params
 */
  export function airTimeExport(params) {
    return request({
      url: envInfo.bgApp.customerWXPath + '/order/export',
      method: 'get',
      params,
    });
  }